import { Box, Layer, Select } from "grommet";
import { Close } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { getPartners } from "../../service/partner_service";
import PrimaryButton from "../elements/primary_button";
import StandardText, { COLOR } from "../elements/standard_text";

const PartnerSelect = ({ partner, changePartner, onClose }) => {

    const [partners, setPartners] = useState([]);
    const [timerId, setTimerId] = useState();
    const [selectedPartner, setSelectedPartner] = useState(partner);

    useEffect(() => setSelectedPartner(partner), [partner]);

    useEffect(() => {
        getPartners()
            .then(res => setPartners(res.data.data))
    }, [])

    const onSearch = (query) => {
        if (timerId) clearTimeout(timerId);
        const _timerId = setTimeout(() => {
            getPartners(query)
                .then(res => setPartners(res.data.data));
        }, 350);
        setTimerId(_timerId);
    }

    const _changePartner = () => {
        if (selectedPartner.id === partner?.id) {
            onClose();
            return;
        }
        changePartner(selectedPartner);
    }

    return (
        <Layer>
            <Box width={'medium'} justify='center' align="center">
                <Box width={'full'} border={'bottom'}>
                    <Box direction="row" pad={'small'} justify='between' align="center">
                        <StandardText label={"Select partner"} style={{ fontWeight: 'bold' }} />
                        <Close onClick={onClose} size='small' style={{ cursor: 'pointer' }} />
                    </Box>
                </Box>
                <Box pad={'small'} width='full' gap="small">
                    <StandardText label={"Partner"} />
                    <Select
                        placeholder="Partner"
                        size="small"
                        labelKey="name"
                        valueKey={{ key: 'id', reduce: true }}
                        onSearch={(text) => {
                            onSearch(text);
                        }}
                        options={partners}
                        onChange={({ value: nextValue }) => {
                            const _partner = partners.find(c => c.id === nextValue);
                            setSelectedPartner(_partner);
                        }}
                        multiple={false}
                    />
                </Box>
                <Box direction="row" justify="end" align="center" gap="small" pad={'medium'}>
                    <PrimaryButton
                        label={"Cancel"}
                        background={COLOR.background}
                        onClick={onClose}
                        color={COLOR.black}
                    />
                    <PrimaryButton
                        label={"Create"}
                        onClick={_changePartner}
                        background='brand'
                    />
                </Box>
            </Box>
        </Layer>
    )
}

export default PartnerSelect;