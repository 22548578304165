import { Box } from "grommet";
import React from "react";
import CustomInput from "../elements/custom_input";
import CustomSelect from "../elements/custom_select";
import StandardText from "../elements/standard_text";
import FormField, { FormFieldInput } from "../elements/form_field";
import { BannerPhoto } from "./deliveroo_view";

const UberEatView = ({
    catalog,
    onCatalogChange,
}) => {
    return (
        <Box width={'full'} align="center" style={{ minHeight: 'auto' }}>
            <Box width={'full'} gap='xsmall' pad={'small'}>
                <StandardText label={'Uber Eat info'} bold />
                <Box pad={{ horizontal: 'small' }} width={'full'} gap='medium' direction='row'>
                    <FormFieldInput
                        label={'Store id'}
                        size="small"
                        value={catalog.ubereat_site_id}
                        onChange={event => onCatalogChange('ubereat_site_id', event.target.value)}
                    />

                    <FormField
                        label={'Menu banner'}
                        size="small"
                        component={
                            <Box width={'full'}>
                                <BannerPhoto catalog={catalog} onCatalogChange={onCatalogChange} />
                            </Box>
                        }
                    />
                </Box>
                <StandardText label={'Order'} bold />
                <Box pad={{ horizontal: 'small' }} gap="xsmall">
                    <FormField
                        label={'Mark orders as Accepted'}
                        size="small"
                        component={
                            <Box width={'medium'}>
                                <CustomSelect
                                    size={'small'}
                                    options={[
                                        "When they are sent to Platform",
                                        "When their status changed to 'Accepted'",
                                    ]}
                                    value={catalog.ubereat_mark_order}
                                    onChange={({ option }) => onCatalogChange('ubereat_mark_order', option)}
                                />
                            </Box>
                        }
                    />
                    <FormField
                        label={'Delivery by Uber Eat ref code'}
                        size="small"
                        component={
                            <Box width={'medium'}>
                                <CustomInput size='small' border
                                    onChange={event => onCatalogChange('ubereat_service_type', event.target.value)}
                                    value={catalog.ubereat_service_type}
                                />
                            </Box>
                        }
                    />
                </Box>

            </Box>
        </Box>
    )
}

export default UberEatView;