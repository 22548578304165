import { Link, navigate } from "gatsby";
import { Box, Button, Card, Layer } from "grommet";
import {
  Download,
  Edit,
  Group,
  Menu,
  Previous,
  Save,
  Storage,
  Upload,
} from "grommet-icons";
import React from "react";
import DeliverooView from "../../../components/catalog/deliveroo_view";
import UberEatView from "../../../components/catalog/ubereat_view";
import { PLATFORM } from "../../../components/catalog/utils";
import History from "../../../components/common/history";
import PartnerSelect from "../../../components/common/partner_select";
import { EventEmitter } from "../../../components/elements/event_emitter";
import { FormFieldInput } from "../../../components/elements/form_field";
import PrimaryButton from "../../../components/elements/primary_button";
import StandardText, {
  COLOR,
} from "../../../components/elements/standard_text";
import LoadingAnimation from "../../../components/loading_animation";
import NavigationFrame from "../../../components/nav_frame";
import {
  checkExistingId,
  getCatalog,
  pullCatalog,
  pushCatalog,
  saveCatalog,
  updateEnventory,
} from "../../../service/catalog_service";
import { getIdAfter } from "../../../service/utils";
import SalesForceInfo from "../../../components/common/salesforce_info";
import PopupConfirmation from "../../../components/elements/confirmation_popup";
import Rating from "../../../components/common/rating";

const TAB = {
  deliveroo: PLATFORM.DELIVEROO,
  ubereat: PLATFORM.UBER_EAT,
};

const validateCatalog = (catalog, platform) => {
  if (platform === PLATFORM.DELIVEROO) {
    if (!catalog.deliveroo_site_id) return "Site location id is not valid";
  }
  if (platform === PLATFORM.UBER_EAT) {
    if (!catalog.ubereat_site_id) return "Store id is not valid";
  }
};
class Catalog extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    console.log(location.state);
    this.state = {
      catalog: undefined,
      changePartner: undefined,
      currentTab: PLATFORM.DELIVEROO,
      data: location.state?.data,
      pull_popup: false
    };
  }

  componentDidMount = () => {
    const id = getIdAfter("catalogs/");
    if (id && !isNaN(parseInt(id))) {
      getCatalog(id).then((res) => {
        this.setState({ catalog: res.data.data });
      });
    } else {
      this.setState({ catalog: {} });
    }
  };

  onCatalogChange = (field, value) => {
    const catalog = this.state.catalog;
    catalog[field] = value;
    this.setState({ ...catalog });
  };

  saveItem = async () => {
    const platform = this.isActive(TAB.deliveroo)
      ? PLATFORM.DELIVEROO
      : PLATFORM.UBER_EAT;
    const res = await checkExistingId(this.state.catalog, platform);
    if (res.data.exists) {
      EventEmitter.dispatch("showMessage", {
        message: `The ${platform} Id already exists in catalog: ${res.data.catalog.name}`,
        messageType: "error",
      });
      return;
    }
    if (!this.state.catalog.salesforce_opportunity_id) {
      EventEmitter.dispatch("showMessage", {
        message: `Veuillez remplir l'information de l'id Salesforce`,
        messageType: "error",
      });
      return;
    }
    saveCatalog(this.state.catalog)
      .then((_) => {
        this.setState({ loading: false });
        EventEmitter.dispatch("showMessage", {
          message: "Catalog is saved successfully!",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const message = err.response?.data?.message || "Unexpected error";
        console.log(err.response?.data);
        EventEmitter.dispatch("showMessage", { message, messageType: "error" });
      });
  };

  pushCatalog = async () => {
    const catalog = this.state.catalog;
    const platform = this.isActive(TAB.deliveroo)
      ? PLATFORM.DELIVEROO
      : PLATFORM.UBER_EAT;
    const errMess = validateCatalog(catalog, platform);
    if (errMess) {
      EventEmitter.dispatch("showMessage", {
        message: errMess,
        messageType: "error",
      });
      return;
    }
    this.setState({ loading: true });
    await saveCatalog(this.state.catalog);
    pushCatalog(catalog.id, platform)
      .then((_) => {
        this.setState({ loading: false });
        EventEmitter.dispatch("showMessage", {
          message: `Catalog is pushed to ${platform} successfully!`,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        EventEmitter.errorHandler(err);
      });
  };

  pullCatalog = async () => {
    const catalog = this.state.catalog;
    const platform = this.isActive(TAB.deliveroo)
      ? PLATFORM.DELIVEROO
      : PLATFORM.UBER_EAT;
    const errMess = validateCatalog(catalog, platform);
    if (errMess) {
      EventEmitter.dispatch("showMessage", {
        message: errMess,
        messageType: "error",
      });
      return;
    }
    this.setState({ loading: true });
    await saveCatalog(this.state.catalog);
    pullCatalog(catalog.id, platform)
      .then((res) => {
        if (res.data) {
          this.setState({ catalog: res.data });
        }
        this.setState({ loading: false, pull_popup: false });
        EventEmitter.dispatch("showMessage", {
          message: "Catalog pulled successfully.",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        EventEmitter.errorHandler(err);
      });
  };

  changePartner = (partner) => {
    this.setState({ loading: true });
    const catalog = this.state.catalog;
    catalog.partner = partner;
    catalog.partner_id = partner.id;
    catalog.partner_name = partner.name;
    saveCatalog(catalog)
      .then((res) => {
        this.setState({
          catalog: res.data.data,
          loading: false,
          changePartner: undefined,
        });
        EventEmitter.dispatch("showMessage", {
          message: "Partner changed successfully.",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        EventEmitter.errorHandler(err);
      });
  };

  isActive = (tab) => this.state.currentTab === tab;
  toTab = (tab) => this.setState({ currentTab: tab });

  pushInventory = () => {
    const platform = this.isActive(TAB.deliveroo)
      ? PLATFORM.DELIVEROO
      : PLATFORM.UBER_EAT;
    this.setState({ loading: true });
    updateEnventory(this.state.catalog.id, platform)
      .then((_) => {
        this.setState({ loading: false });
        EventEmitter.dispatch("showMessage", {
          message: "Inventory updated successfully.",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        EventEmitter.errorHandler(err);
      });
  };

  render() {
    return (
      <NavigationFrame>
        {this.state.catalog ? (
          <Box
            width={"xlarge"}
            pad="medium"
            style={{ height: "100vh", minHeight: "auto", overflowY: "auto" }}
            gap="medium"
          >
            <Box
              justify="between"
              width={"full"}
              pad={{ bottom: "small" }}
              direction="row"
              style={{ minHeight: "auto" }}
              border="bottom"
            >
              <PrimaryButton
                label={"Back"}
                onClick={() => {
                  if (typeof window === "undefined") return;
                  if (window.location.search.includes('fromCatalogs')) {
                    navigate("/catalogs", { state: { data: this.state.data } });
                  }
                  else {
                    window.history.back();
                  }
                }}
                icon={<Previous size="small" color={"#fff"} />}
              />
              <Box justify="center" align="center">
                <StandardText label={this.state.catalog?.name}
                  bold size="large" color={COLOR.black}
                />
              </Box>
              <Box direction="row" gap="small">
                <Box align="center" justify="center" direction="row">
                  <Card
                    round={{ corner: "left", size: "medium" }}
                    style={{ height: 40 }}
                    pad={{ vertical: "xsmall", horizontal: "small" }}
                    onClick={() => this.toTab(TAB.deliveroo)}
                    background={
                      this.isActive(TAB.deliveroo) ? COLOR.green : "#fff"
                    }
                    justify="center"
                  >
                    {this.isActive(TAB.deliveroo) ? (
                      <StandardText
                        label={TAB.deliveroo}
                        bold
                        color="#fff"
                        size="small"
                      />
                    ) : (
                      <StandardText label={TAB.deliveroo} size="small" />
                    )}
                  </Card>
                  <Card
                    round={{ corner: "right", size: "medium" }}
                    style={{ height: 40 }}
                    pad="small"
                    onClick={() => this.toTab(TAB.ubereat)}
                    background={
                      this.isActive(TAB.ubereat) ? COLOR.green : "#fff"
                    }
                    justify="center"
                  >
                    {this.isActive(TAB.ubereat) ? (
                      <StandardText
                        label={TAB.ubereat}
                        bold
                        color="#fff"
                        size="small"
                      />
                    ) : (
                      <StandardText label={TAB.ubereat} size="small" />
                    )}
                  </Card>
                </Box>
              </Box>
            </Box>
            <Box width={"full"} gap="small">
              <Box
                width={"xlarge"}
                justify="between"
                style={{ minHeight: "auto" }}
                direction="row"
                align="center"
              >
                <Box round={{ size: "small" }} direction="row" gap="xsmall">
                  <Link to={`/partners/${this.state.catalog?.partner?.id}`}>
                    <Button
                      icon={<Group size="small" color="brand" />}
                      size="small"
                      label={
                        <StandardText size="xsmall" label={"Open partner"} />
                      }
                    ></Button>
                  </Link>
                  <Link to={`/catalogs/${this.state.catalog?.id}/products`}>
                    <Button
                      icon={<Menu size="small" color="brand" />}
                      size="small"
                      label={<StandardText size="xsmall" label={"Open menu"} />}
                    ></Button>
                  </Link>
                </Box>
                <Box justify="end" direction="row" gap="small" pad={"small"}>
                  <PrimaryButton
                    label={"Push catalog"}
                    icon={<Upload color="#fff" size="small" />}
                    onClick={this.pushCatalog}
                  />
                  <PrimaryButton
                    label={"Pull catalog"}
                    icon={<Download color="#fff" size="small" />}
                    onClick={() => this.setState({ pull_popup: true })}
                  />
                  <PrimaryButton
                    label={"Push inventory"}
                    icon={<Storage color="#fff" size="small" />}
                    onClick={this.pushInventory}
                  />
                  <PrimaryButton
                    label={"Save"}
                    icon={<Save color="#fff" size="small" />}
                    onClick={this.saveItem}
                  />
                </Box>
              </Box>
              <Box
                width={"xlarge"}
                justify="center"
                style={{ minHeight: "auto" }}
                pad="small"
              >
                <SalesForceInfo
                  object_id={this.state.catalog.salesforce_opportunity_id}
                  onChange={val => this.onCatalogChange('salesforce_opportunity_id', val)}
                  label={"Opportunity id"}
                  size="small"
                />
                <Box justify="between" align="center" direction="row">
                  <StandardText label={"Partenaire"} bold />
                  <Button
                    size="small"
                    icon={<Edit size="small" color="brand" />}
                    label={<StandardText label={"Change partner"} />}
                    onClick={() => this.setState({ changePartner: true })}
                  />
                </Box>
                <Box
                  pad={{ horizontal: "small" }}
                  gap="medium"
                  width={"full"}
                  direction="row"
                >
                  <FormFieldInput
                    label={"Name"}
                    size="small"
                    value={this.state.catalog.partner?.name}
                  />
                  <FormFieldInput
                    label={"Contact"}
                    size="small"
                    value={this.state.catalog.partner?.contact}
                  />
                </Box>
              </Box>
              <Box
                width={"xlarge"}
                justify="center"
                style={{ minHeight: "auto" }}
              >
                {this.state.currentTab === TAB.deliveroo ? (
                  <DeliverooView
                    catalog={this.state.catalog}
                    onCatalogChange={this.onCatalogChange}
                    save={this.saveItem}
                    pushCatalog={this.pushCatalog}
                    pullCatalog={this.pullCatalog}
                  />
                ) : (
                  <UberEatView
                    catalog={this.state.catalog}
                    onCatalogChange={this.onCatalogChange}
                    save={this.saveItem}
                    pushCatalog={this.pushCatalog}
                    pullCatalog={this.pullCatalog}
                  />
                )}
              </Box>
              <Box
                width={"xlarge"}
                justify="center"
                style={{ minHeight: "auto" }}
                pad="small"
              >
                <Box pad="small">
                  <StandardText
                    label={"Histories"}
                    size="medium"
                    style={{ fontWeight: "bold" }}
                  />
                </Box>
                <Box width={"full"} style={{ maxHeight: 700 }}>
                  <History
                    resource_type={"catalog"}
                    resource_id={this.state.catalog.id}
                    platform={
                      this.state.currentTab === TAB.deliveroo
                        ? PLATFORM.DELIVEROO
                        : PLATFORM.UBER_EAT
                    }
                  />
                </Box>
              </Box>
              <Box
                width={"xlarge"}
                justify="center"
                style={{ minHeight: "auto" }}
                pad="small"
              >
                <Box pad="small">
                  <StandardText
                    label={"Avis"}
                    size="medium"
                    style={{ fontWeight: "bold" }}
                  />
                </Box>
                <Box width={"full"} style={{ maxHeight: 700, minHeight: 'auto', overflow: 'auto' }}>
                    <Rating
                      catalog_id={this.state.catalog?.id}
                    />
                  </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <LoadingAnimation />
        )}

        {this.state.loading ? (
          <Layer>
            <Box
              justify="center"
              align="center"
              style={{ width: 300, height: 300 }}
            >
              <LoadingAnimation />
            </Box>
          </Layer>
        ) : null}

        {this.state.changePartner ? (
          <Layer>
            <PartnerSelect
              partner={this.state.catalog.partner}
              onClose={() => this.setState({ changePartner: undefined })}
              changePartner={this.changePartner}
            />
          </Layer>
        ) : null}
        {this.state.pull_popup && <PopupConfirmation
          message={"Do you want to pull the catalog?"}
          primaryActionText={"Pull catalog"}
          primaryAction={this.pullCatalog}
          secondaryAction={() => this.setState({ pull_popup: false })}
          secondaryActionText={"Annuler"}
          close={() => this.setState({ pull_popup: false })}
        />}
      </NavigationFrame>
    );
  }
}
export default Catalog;
