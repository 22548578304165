import { Box, FileInput, Image } from "grommet";
import { Refresh, Trash } from "grommet-icons";
import React, { useState } from "react";
import { upload } from "../../service/common_service";
import CustomInput from "../elements/custom_input";
import CustomSelect from "../elements/custom_select";
import StandardText, { COLOR } from "../elements/standard_text";
import LoadingAnimation from "../loading_animation";
import FormField, { FormFieldInput } from "../elements/form_field";
import { EventEmitter } from "../elements/event_emitter";
import catalogService from "../../service/catalog_service";

const DeliverooView = ({
    catalog,
    onCatalogChange,
}) => {

    const getDeliverooBrandId = () => {
        const deliveroo_site_id = catalog.deliveroo_site_id;
        if (!deliveroo_site_id) {
            EventEmitter.dispatch('showMessage', {
                message: 'Site location id is not valid',
                messageType: 'warning'
            })
            return;
        }
        catalogService.getDeliverooBrandId(deliveroo_site_id)
            .then(res => {
                const { brand_id } = res.data;
                if (brand_id) {
                    onCatalogChange('deliveroo_brand_id', brand_id);
                } else {
                    EventEmitter.dispatch('showMessage', {
                        message: 'Brand id not found for site location id: ' + deliveroo_site_id,
                        messageType: 'warning'
                    })
                }
            }).catch(EventEmitter.errorHandler)
    }

    return (
        <Box width={'full'} align="center" style={{ minHeight: 'auto' }}>
            <Box width={'full'} gap='xsmall' pad={'small'}>
                <StandardText label={'Deliveroo info'} bold />
                <Box pad={{ horizontal: 'small' }} width={'full'} gap='medium' direction='row'>
                    <FormFieldInput
                        label={'Brand id'}
                        size="small"
                        value={catalog.deliveroo_brand_id}
                        icon={<Refresh color={COLOR.green} size="small" style={{ cursor: 'pointer' }} onClick={getDeliverooBrandId} />}
                    />
                    <FormFieldInput
                        label={'Site location id'}
                        size="small"
                        value={catalog.deliveroo_site_id}
                        onChange={event => onCatalogChange('deliveroo_site_id', event.target.value)}
                    />

                </Box>
                <Box width={'full'} gap='medium' pad={'small'} direction='row'>
                    <FormFieldInput
                        label={'Menu Id'}
                        size="small"
                        value={catalog.deliveroo_menu_id}
                        onChange={event => onCatalogChange('deliveroo_menu_id', event.target.value)}
                    />
                    <FormField
                        label={'Menu banner'}
                        size="small"
                        component={
                            <Box width={'medium'}>

                                <Box width={'full'}>
                                    <BannerPhoto catalog={catalog} onCatalogChange={onCatalogChange} />
                                </Box>
                            </Box>
                        }
                    />

                </Box>
                <StandardText label={'Order'} bold />
                <Box pad={{ horizontal: 'small' }} gap="xsmall">
                    <FormField
                        label={'Mark orders as Accepted'}
                        size="small"
                        component={
                            <Box width={'medium'}>
                                <CustomSelect
                                    size={'small'}
                                    options={[
                                        "When they are sent to Platform",
                                        "When their status changed to 'Accepted'",
                                    ]}
                                    value={catalog.deliveroo_mark_order}
                                    onChange={({ option }) => onCatalogChange('deliveroo_mark_order', option)}
                                />
                            </Box>
                        }
                    />
                    <FormField
                        label={'Delivery by Deliveroo ref code'}
                        size="small"
                        component={
                            <Box width={'medium'}>
                                <CustomInput size='small' border
                                    onChange={event => onCatalogChange('deliveroo_service_type', event.target.value)}
                                    value={catalog.deliveroo_service_type}
                                />
                            </Box>
                        }
                    />

                </Box>
            </Box>
        </Box>
    )
}

const BannerPhoto = ({ catalog, onCatalogChange }) => {

    const [loading, setLoading] = useState()

    const _upload = (files) => {
        upload(
            () => setLoading(true),
            files,
            (urls) => {
                setLoading(false);
                onCatalogChange('deliveroo_menu_banner', urls[0].url);
            }
        )
    }

    return (
        catalog.deliveroo_menu_banner ?

            (<Box style={{ width: 150, height: 150, boxShadow: 'none', position: 'relative' }}
                hoverIndicator={{ background: { color: '#999', opacity: 0.5 }, elevation: 'medium', opacity: 0.5 }}
                onClick={() => console.log('click')}
            >
                <Box width={'full'} style={{ position: 'absolute' }}>
                    <Trash color="#999" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onCatalogChange('deliveroo_menu_banner', undefined);
                    }} />
                </Box>
                <Image src={catalog.deliveroo_menu_banner} fit='cover' />
            </Box>) :
            <Box style={{ width: 150, height: 150, boxShadow: 'none' }} background='#F0F0F0' justify="center" align="center"
                onClick={() => console.log('add')}
            >
                {loading ?
                    (<Box size='small'>
                        <LoadingAnimation />
                    </Box>) :

                    (
                        <FileInput
                            multiple={false}
                            width='full'
                            messages={{
                                browse: "+",
                                dropPrompt: "Add"
                            }}
                            onChange={(event) => _upload(event.target.files)}
                        />)
                }
            </Box>
    )
}

export default DeliverooView;

export {
    BannerPhoto
}